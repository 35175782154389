<template>
  <v-card outlined width="800px" height="300px" class="mt-10 mx-auto">
    <v-container fluid>
      <v-row justify="center">
        <v-col>
          <v-text-field placeholder="اسم السلفة" outlined v-model="name"></v-text-field>
          <v-text-field placeholder="عدد الأفساط" outlined v-model="payments_number"></v-text-field>
        </v-col>
        <v-col>
          <v-autocomplete
              :label="$t('account name')"
              dense
              outlined
              :items="accountItems"
              :loading="accountLoading"
              color="#757575"
              item-text="name"
              item-value="id"
              v-model="SelectedAccount"
              @keypress="fetchAutoCompleteAccount($event)"
            >
            </v-autocomplete>
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-6">
        <v-col cols="2">
          <v-btn color="primary" class="save-button" @click="submit">{{
            $t("save")
          }}</v-btn>
        </v-col>
        <v-col cols="2">
          <v-btn color="error" class="cancel-button" @click="cancel">{{
            $t("cancel")
          }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import axios from "axios";
import debounce from "../../../../../helpers/debounce";

export default {
  data() {
    return {
      accountItems: [],
      accountLoading: false,
      accountList: [],
      AccountList: [],
      SelectedAccount: {},
      payments_number: "",
      name: "",
    };
  },
 async created() {
  if (this.$route.params.id != "CreateNewSalaryLoan") {
    try {
          const searchResult = await axios.get(
            "/accounting/account/auto-complete?query=1"
          );
          this.accountItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } 
      await axios.get(`/hr/loan/type/${this.$route.params.id}`).then((res) => {
        this.name = res.data.data.name;
        this.payments_number = res.data.data.payments_number;
        this.SelectedAccount = res.data.data.account_id;
      });
    }
    
  },
  methods: {
    async fetchAutoCompleteAccount(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.accountLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/account/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          this.accountItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.accountLoading = false;
        }
      }, 500)();
    },
    cancel() {
      this.$router.push("/HR/initiate");
    },
    submit() {
       if (this.$route.params.id == "CreateNewSalaryLoan") {
        axios
          .post("/hr/loan/type", {
            name: this.name,
            payments_number: this.payments_number,
            account_id: this.SelectedAccount,
          })
          .then((res) => {
            if (res.status == 200) {
              this.$router.go(-1);
              this.$Notifications(
                "تمت اضافة سلفة بنجاح",
                { rtl: true, timeout: 2500 },
                "success"
              );
              (this.name = ""),
                (this.payments_number = "")((this.SelectedAccount = {}));
            }
          });
      } else {
        
        axios
          .put(`/hr/loan/type/${this.$route.params.id}`, {
            name: this.name,
            payments_number: this.payments_number,
            account_id: this.SelectedAccount,
          })
          .then((res) => {
            if (res.status == 200) {
              this.$router.go(-1);
              this.$Notifications(
                "تمت تعديل سلفة بنجاح",
                { rtl: true, timeout: 2500 },
                "success"
              );
              (this.name = ""),
                (this.payments_number = "")((this.SelectedAccount = {}));
            }
          });

      }
    },
  },
};
</script>